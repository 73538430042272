import React, { useCallback, useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

// Styles sheet
import "./CHT.css";

// Compnenents
import LightBox from "../../Components/LightBox/LightBox";
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/SectionAnimation/SecAnimation";
import Password from "../../Components/AuthPassword/Password";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";

// Assets
import maxicareIllustration1 from "../../assets/img/MaxicareIllustration1.png";
import maxicareIllustration2 from "../../assets/img/MaxicareIllustration2.png";
import maxicareIllustration3 from "../../assets/img/MaxicareIllustration3.png";
import maxicareIllustration4 from "../../assets/img/MaxicareIllustration4.png";
import transition2 from "../../Components/PageTransition2/Transition2";

function Maxicare() {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  const [isPasswordProtected, setIsPasswordProtected] = useState(true);

  // Function for if user click the image to open.
  const handleLightboxOpen = useCallback((image) => {
    setIsLightboxOpen(true);
    setLightboxImage(image);
  }, []);

  // Function for if user click the x or close icon to close.
  const handleCloseLightbox = useCallback(() => {
    setIsLightboxOpen(false);
    setLightboxImage("");
  }, []);

  const handleSuccess = () => {
    setIsPasswordProtected(false);
  };

  useEffect(() => {
    if (isPasswordProtected) {
      document.body.style.overflow = "hidden";
    } else {
      scroll.scrollToTop({ behavior: "none", passive: false });
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isPasswordProtected]);

  return (
    <>
      {isPasswordProtected ? (
        <Password onSuccess={handleSuccess} />
      ) : (
        <>
          <SecAnimation />
          <GraphBg1 />
          {/* <main> */}
          {/* Hero section */}
          <section className="sections animsec">
            <div className="sc-outer-container flex">
              <div className="maxicareCover cover"></div>
              <div className="sc-inner-container">
                <div className="content p-block-1 animatedcont">
                  <h5 className="d-sm-bold mb-0_25">
                    Designing Maxicare’s Mascot and Illustrations for a Web
                    Application
                  </h5>
                  <div className="chip-red">
                    <p className="t-sm-medium">Illustrations</p>
                  </div>
                  <p className="t-md-medium de-t-xl-regular">
                    Maxicare, a leading healthcare provider, needed a new
                    Members Portal to improve user experience and expand the
                    platform’s functionality. The existing portal was outdated
                    and difficult to use, leading to frustration among users. As
                    a result, the decision was made to create an entirely new
                    portal with enhanced usability and additional features
                    tailored to meet evolving user needs.
                  </p>
                </div>
                <div className="content p-block-1">
                  <div className="row">
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Role</p>
                      <p className="t-md-medium de-t-xl-medium">
                        UI/UX Designer, Illustrator
                      </p>
                    </div>
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Duration</p>
                      <p className="t-md-medium de-t-xl-medium">9 Months</p>
                    </div>
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Tools</p>
                      <p className="t-md-medium de-t-xl-medium">
                        Figma, Adobe Illustrator, Procreate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Initial Design Concept section */}
          <section className="sections">
            <div className="sc-containers flex-2 row-rev">
              <div className="content">
                <h5 className="d-sm-bold">Initial Design Concept</h5>
                <p className="t-md-regular de-t-xl-regular">
                  I started with a rough sketch of the bear mascot, basing it on
                  the current style of their existing bear but adding my own
                  twist. This version aimed to enhance the overall design while
                  keeping it familiar, serving as the foundation for further
                  refinement.
                </p>
              </div>
              <div className="content">
                <img
                  src={maxicareIllustration1}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration1)}
                />
              </div>
            </div>
          </section>

          {/* Digital convenience section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2 animatedcont">
              <div className="content">
                <h5 className="d-sm-bold">My Approach</h5>
                <p className="t-md-regular de-t-xl-regular">
                  I developed a new version of the bear mascot that had a more
                  polished and adaptable style. The redesign focused on aligning
                  with Maxicare’s overall branding, ensuring consistency in
                  poses and expressions across different user interface
                  elements. The new mascot was designed to be more versatile,
                  appearing in various scenarios throughout the web application.
                </p>
              </div>
              <div className="content">
                <img
                  src={maxicareIllustration2}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration2)}
                />
              </div>
            </div>
          </section>

          {/* The screens section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <p className="t-md-regular de-t-xl-regular">
                  In this updated version, I dressed the bear as a doctor,
                  replacing the casual T-shirt with a doctor’s coat to better
                  reflect Maxicare’s healthcare focus and give the character a
                  more professional, relevant appearance.
                </p>
              </div>
            </div>
          </section>

          {/* Characters section */}
          <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration3}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration3)}
                />
              </div>
              <div className="content animatedcont">
                <h5 className="d-sm-bold">The Presentation</h5>
                <p className="t-md-regular de-t-xl-regular">
                  When I presented the new mascot design to the stakeholders, I
                  explained how the updated bear would bring consistency and
                  versatility to their brand. However, they were strongly
                  attached to their original mascot and were firm about not
                  replacing it. Despite the improvements in design, they
                  preferred to stick with the familiar bear character and
                  decided not to incorporate the new bear into the web
                  application we were building.
                </p>
              </div>
            </div>
          </section>

          {/* Characters section */}
          <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <h5 className="d-sm-bold">The Outcome</h5>
                <p className="t-md-regular de-t-xl-regular">
                  After further discussions, we agreed to use a more generic
                  illustration style instead of the bear mascot. This approach
                  maintained visual consistency without altering the
                  application’s design.
                </p>
              </div>
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration4}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration4)}
                />
              </div>
              <p className="t-md-regular de-t-xl-regular animatedcont">
                Here are some of the generic illustrations I developed for the
                application. These designs were crafted to maintain visual
                consistency and enhance the user experience while adhering to
                the client’s preferences.
              </p>
            </div>
          </section>

          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">
                  Key Takeaways and Conclusions
                </h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  This project highlighted the importance of adaptability in
                  design. Although the new bear mascot wasn’t used, I quickly
                  adapted to deliver a solution that met the stakeholders’
                  needs. The time spent on this process improved my skills in
                  managing client expectations and maintaining design integrity.
                  Even though the web app is not yet deployed in production,
                  using generic illustrations resolved concerns and aligned with
                  the project’s goals, contributing to a successful outcome.
                </p>
              </div>
            </div>
          </section>

          {/* LightBox for thumbsnail */}
          <LightBox
            isOpen={isLightboxOpen}
            image={lightboxImage}
            onClose={handleCloseLightbox}
          />

          {/* Button to scroll up */}
          <ScrollToTopButton />
        </>
      )}
    </>
  );
}

export default transition2(Maxicare);
