import React, { useCallback, useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

// Styles sheet
import "./CHT.css";

// Compnenents
import LightBox from "../../Components/LightBox/LightBox";
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/SectionAnimation/SecAnimation";
import Password from "../../Components/AuthPassword/Password";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";

// Assets
import maxicareIllustration5 from "../../assets/img/MaxicareIllustration5.png";
import maxicareIllustration6 from "../../assets/img/MaxicareIllustration6.png";
import maxicareIllustration7 from "../../assets/img/MaxicareIllustration7.png";
import maxicareIllustration8 from "../../assets/img/MaxicareIllustration8.gif";
import maxicareIllustration9 from "../../assets/img/MaxicareIllustration9.gif";
import transition2 from "../../Components/PageTransition2/Transition2";

function Maxicare() {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  const [isPasswordProtected, setIsPasswordProtected] = useState(true);

  // Function for if user click the image to open.
  const handleLightboxOpen = useCallback((image) => {
    setIsLightboxOpen(true);
    setLightboxImage(image);
  }, []);

  // Function for if user click the x or close icon to close.
  const handleCloseLightbox = useCallback(() => {
    setIsLightboxOpen(false);
    setLightboxImage("");
  }, []);

  const handleSuccess = () => {
    setIsPasswordProtected(false);
  };

  useEffect(() => {
    if (isPasswordProtected) {
      document.body.style.overflow = "hidden";
    } else {
      scroll.scrollToTop({ behavior: "none", passive: false });
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isPasswordProtected]);

  return (
    <>
      {isPasswordProtected ? (
        <Password onSuccess={handleSuccess} />
      ) : (
        <>
          <SecAnimation />
          <GraphBg1 />
          {/* <main> */}
          {/* Hero section */}
          <section className="sections animsec">
            <div className="sc-outer-container flex">
              <div className="maxicareCover cover"></div>
              <div className="sc-inner-container">
                <div className="content p-block-1 animatedcont">
                  <h5 className="d-sm-bold mb-0_25">
                  Designing the New Maxicare Members Portal Platform
                  </h5>
                  <div className="chip-green">
                    <p className="t-sm-medium">Web progressive app</p>
                  </div>
                  <p className="t-md-medium de-t-xl-regular">
                    Maxicare, a leading healthcare provider, needed a new
                    Members Portal to improve user experience and expand the
                    platform’s functionality. The existing portal was outdated
                    and difficult to use, leading to frustration among users. As
                    a result, the decision was made to create an entirely new
                    portal with enhanced usability and additional features
                    tailored to meet evolving user needs.
                  </p>
                </div>
                <div className="content p-block-1">
                  <div className="row">
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Role</p>
                      <p className="t-md-medium de-t-xl-medium">
                        UI/UX Designer, Illustrator
                      </p>
                    </div>
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Duration</p>
                      <p className="t-md-medium de-t-xl-medium">9 Months</p>
                    </div>
                  </div>
                </div>

                <div className="content p-block-1">
                  <div className="row">
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Team</p>
                      <p className="t-md-medium de-t-xl-medium">
                        Lead UI/UX Designer, Junior UI/UX Designer, Project
                        Manager, CX Designer
                      </p>
                    </div>
                    <div className="col animatedcont">
                      <p className="t-sm-regular">Tools</p>
                      <p className="t-md-medium de-t-xl-medium">
                        Figma, Adobe Fresco, Notion, Adobe Illustrator,
                        Procreate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Project Goal section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">Project Goal</h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  The primary goal was to build a new Members Portal from the
                  ground up, featuring a modern user interface and improved user
                  experience. Additionally, the portal would include new
                  features that were not present in the old version, offering
                  users more comprehensive tools to manage their healthcare
                  plans, access services, and interact with the Maxicare
                  ecosystem more effectively.
                </p>
              </div>
            </div>
          </section>

          {/* My Rolse section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">My Role</h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  As the UI/UX Designer, I was responsible for creating both
                  low-fidelity and high-fidelity designs, illustrations, and the
                  design system. I collaborated closely with the Customer
                  Experience Designer, who led the research and user journey
                  mapping, and was also part of the team working on affinity
                  mapping. My focus was on the visual design and ensuring that
                  the portal’s UI aligned with the new and expanded
                  functionalities.
                </p>
              </div>
            </div>
          </section>

          {/* Problem Statement section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">Problem Statement</h5>
                <p className="t-md-regular de-t-xl-regular">
                  The original Members Portal had significant usability issues
                  and lacked many features that users needed:
                  <br />
                  <br />
                  • Poor Usability: Users struggled to navigate the existing
                  portal, making it difficult to access important features.
                  <br />
                  <br />
                  • Limited Functionality: The old portal offered only basic
                  features, which did not fully meet the needs of Maxicare
                  members.
                  <br />
                  <br />
                  • Outdated Design: The design was not aligned with modern user
                  expectations, leading to a subpar user experience.
                  <br />
                  <br />
                  Given these issues, we aimed not only to redesign the portal
                  but also to expand its capabilities with new features and a
                  user-centered approach.
                </p>
              </div>
            </div>
          </section>

          {/* Research & Discovery section */}
          <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <h5 className="d-sm-bold">Research & Discovery</h5>
                <p className="t-md-regular de-t-xl-regular">
                  The Customer Experience Designer led extensive user research
                  to understand the needs, pain points, and goals of Maxicare’s
                  users. I participated in affinity mapping sessions where we
                  synthesized this research, categorizing user insights to
                  inform the design process.
                </p>
              </div>
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration5}
                  alt="Illustration"
                  className="sc-img lb-img bordered-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration5)}
                />
              </div>
              <p className="t-md-regular de-t-xl-regular animatedcont">
                Key Findings:
                <br />
                Users preferred simple search by city or landmark, seamless
                booking with real-time availability, and clear service
                selection. They valued distance indicators and booking
                confirmations via email or text without needing an account.
              </p>
            </div>
          </section>

          {/* Low-Fidelity Wireframes section */}
          <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <h5 className="d-sm-bold">Low-Fidelity Wireframes</h5>
                <p className="t-md-regular de-t-xl-regular">
                  Starting with low-fidelity wireframes, We focused on
                  establishing a new structure that incorporated the additional
                  features identified in the research phase. We aimed to
                  streamline user flows while also ensuring that new
                  functionalities were easily accessible.
                </p>
              </div>
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration6}
                  alt="Illustration"
                  className="sc-img lb-img bordered-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration6)}
                />
              </div>
            </div>
          </section>

          {/* High-Fidelity Designs section */}
          <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <h5 className="d-sm-bold">High-Fidelity Designs</h5>
                <p className="t-md-regular de-t-xl-regular">
                  After wireframes were approved, We moved to high-fidelity
                  designs. These designs introduced a fresh, modern look that
                  aligned with Maxicare’s branding but also elevated the user
                  experience with new visual and interactive elements.
                </p>
              </div>
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration7}
                  alt="Illustration"
                  className="sc-img lb-img bordered-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration7)}
                />
              </div>
            </div>
          </section>

          {/* Book a Consultation section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">Book a Consultation</h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  The Book a Consultation feature was redesigned to simplify the
                  process. Users select their policy, find the nearest clinic,
                  choose a date and time, select a doctor, and confirm the
                  appointment. Notifications are sent via email and mobile, and
                  upcoming appointments are easily trackable on the dashboard.
                </p>
              </div>
            </div>
          </section>

          <section className="sections animsec">
            <div className="sc-containers">
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration8}
                  alt="Illustration"
                  className="sc-img lb-img gifed bordered-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration8)}
                />
              </div>
            </div>
          </section>

          {/* Request a Medical Test section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">Request a Medical Test</h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  For Medical Test requests, users upload a diagnostic order,
                  choose procedures, select a clinic and time, and confirm via
                  OTP. Appointments appear on the dashboard with notifications
                  for updates.
                </p>
              </div>
            </div>
          </section>

          <section className="sections animsec">
            <div className="sc-containers">
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration9}
                  alt="Illustration"
                  className="sc-img lb-img gifed bordered-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration9)}
                />
              </div>
            </div>
          </section>

          {/* Initial Design Concept section */}
          {/* <section className="sections">
            <div className="sc-containers flex-2 row-rev">
              <div className="content">
                <h5 className="d-sm-bold">Initial Design Concept</h5>
                <p className="t-md-regular de-t-xl-regular">
                  I started with a rough sketch of the bear mascot, basing it on
                  the current style of their existing bear but adding my own
                  twist. This version aimed to enhance the overall design while
                  keeping it familiar, serving as the foundation for further
                  refinement.
                </p>
              </div>
              <div className="content">
                <img
                  src={maxicareIllustration1}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration1)}
                />
              </div>
            </div>
          </section> */}

          {/* Digital convenience section */}
          {/* <section className="sections animsec">
            <div className="sc-containers flex-2 animatedcont">
              <div className="content">
                <h5 className="d-sm-bold">My Approach</h5>
                <p className="t-md-regular de-t-xl-regular">
                  I developed a new version of the bear mascot that had a more
                  polished and adaptable style. The redesign focused on aligning
                  with Maxicare’s overall branding, ensuring consistency in
                  poses and expressions across different user interface
                  elements. The new mascot was designed to be more versatile,
                  appearing in various scenarios throughout the web application.
                </p>
              </div>
              <div className="content">
                <img
                  src={maxicareIllustration2}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration2)}
                />
              </div>
            </div>
          </section> */}

          {/* The screens section */}
          {/* <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <p className="t-md-regular de-t-xl-regular">
                  In this updated version, I dressed the bear as a doctor,
                  replacing the casual T-shirt with a doctor’s coat to better
                  reflect Maxicare’s healthcare focus and give the character a
                  more professional, relevant appearance.
                </p>
              </div>
            </div>
          </section> */}

          {/* Characters section */}
          {/* <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration3}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration3)}
                />
              </div>
              <div className="content animatedcont">
                <h5 className="d-sm-bold">The Presentation</h5>
                <p className="t-md-regular de-t-xl-regular">
                  When I presented the new mascot design to the stakeholders, I
                  explained how the updated bear would bring consistency and
                  versatility to their brand. However, they were strongly
                  attached to their original mascot and were firm about not
                  replacing it. Despite the improvements in design, they
                  preferred to stick with the familiar bear character and
                  decided not to incorporate the new bear into the web
                  application we were building.
                </p>
              </div>
            </div>
          </section> */}

          {/* Characters section */}
          {/* <section className="sections animsec">
            <div className="sc-containers flex">
              <div className="content animatedcont">
                <h5 className="d-sm-bold">The Outcome</h5>
                <p className="t-md-regular de-t-xl-regular">
                  After further discussions, we agreed to use a more generic
                  illustration style instead of the bear mascot. This approach
                  maintained visual consistency without altering the
                  application’s design.
                </p>
              </div>
              <div className="content animatedcont">
                <img
                  src={maxicareIllustration4}
                  alt="Illustration"
                  className="sc-img lb-img"
                  loading="lazy"
                  onClick={() => handleLightboxOpen(maxicareIllustration4)}
                />
              </div>
              <p className="t-md-regular de-t-xl-regular animatedcont">
                Here are some of the generic illustrations I developed for the
                application. These designs were crafted to maintain visual
                consistency and enhance the user experience while adhering to
                the client’s preferences.
              </p>
            </div>
          </section> */}

          {/* The screens section */}
          <section className="sections animsec">
            <div className="sc-containers flex-2">
              <div className="content animatedcont">
                <h5 className="d-sm-bold t-center">Handoff & Current Status</h5>
                <p className="t-md-regular de-t-xl-regular t-center">
                  After finalizing the design, the project was successfully
                  handed off to the development team, who are currently building
                  the Progressive Web Application based on the approved UI/UX
                  designs. Comprehensive documentation, prototypes, and assets
                  were provided to ensure a smooth transition and alignment
                  between design and development. We continue to collaborate
                  with the developers to address any design clarifications and
                  ensure the final product adheres to the vision laid out during
                  the design phase.
                </p>
              </div>
            </div>
          </section>
          {/* </main> */}

          {/* LightBox for thumbsnail */}
          <LightBox
            isOpen={isLightboxOpen}
            image={lightboxImage}
            onClose={handleCloseLightbox}
          />

          {/* Button to scroll up */}
          <ScrollToTopButton />
        </>
      )}
    </>
  );
}

export default transition2(Maxicare);