import React, { useState, useCallback, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import ScrollArrow from "../../assets/img/ScrollArrow.svg";
import "../ScrollToTopButton/ScrollToTopButton.css";

const ScrollToTopButton = () => {
  const [isHideBtn, setHideBtn] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  // Function for scroll to top button.
  // If the scrollY is greater than the current then hide the button up,
  // Else then show the button up.
  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setHideBtn(false);
    } else {
      setHideBtn(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  // To avoid recreating element and to ensure it.
  useEffect(() => {
    const handleAutoHide = () => setHideBtn(true);

    let timeoutId = setTimeout(handleAutoHide, 2000);

    const onScroll = () => {
      clearTimeout(timeoutId);
      handleScroll();
      timeoutId = setTimeout(handleAutoHide, 2000);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", onScroll);
    };
  }, [handleScroll]);

  return (
    <button
      className={`stt-btn ${isHideBtn ? "hideBtn" : "showBtn"}`}
      onClick={() => {
        scroll.scrollToTop({ behavior: "smooth", passive: true });
      }}
    >
      <img src={ScrollArrow} alt="Arrow" />
    </button>
  );
};

export default ScrollToTopButton;
